<template>
  <div>
    <v-card elevation="0" class="mt-3">
      <v-card-title>
        {{ $t("import") }}
      </v-card-title>
      <v-card-text>
        <v-file-input
          accept=".json"
          outlined
          :value="jsonFile"
          @change="setFileContent($event)"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-alert
          class="mx-auto"
          v-if="!isSelectedStudy && !!jsonFileContent"
          type="error"
        >
          {{
            $t("notSameStudy", {
              required: currentStudy.name,
              got: jsonFileContent.name
            })
          }}
        </v-alert>
        <v-spacer v-if="isSelectedStudy"></v-spacer>
        <div v-if="isSelectedStudy">
          <action-panel @action="onAction($event)"> </action-panel>
        </div>
      </v-card-actions>
    </v-card>

    <!-- Double confirmation -->
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title>{{ $t("updateStudy") }}</v-card-title>
        <v-card-text>{{ $t("alertUpdate") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" outlined color="danger">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn @click="saveStudy()" color="primary">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar :value="completed" color="success" top>
      {{ $t("completed") }}
    </v-snackbar>
  </div>
</template>

<script>
import ActionPanel from "../admin/ActionPanelAdmin.vue";
import { StudyBuilder } from "spiral";
import { studyDeserialize } from "spiral-client";
export default {
  data() {
    return {
      dialog: false,
      completed: false,
      jsonFile: undefined,
      jsonFileContent: undefined
    };
  },
  computed: {
    isSelectedStudy() {
      return this.jsonFileContent?.name == this.$route.params.study;
    }
  },
  methods: {
    async setFileContent(file) {
      this.jsonFile = file;
      this.jsonFileContent = file ? JSON.parse(await file?.text()) : undefined;
    },
    async onAction({ type }) {
      switch (type) {
        case "save":
          this.dialog = true;
          break;
        case "cancel":
          this.cancelImport();
          break;
      }
    },
    async saveStudy() {
      this.dialog = false;
      const b = new StudyBuilder();
      studyDeserialize(b, this.jsonFileContent);
      await this.drivers.studyDriver.save(b.get());
      const updatedStudy = await this.drivers.studyDriver.getByName(
        this.$route.params.study
      );
      this.setStudy(updatedStudy);
      this.completed = true;
    },
    cancelImport() {
      this.jsonFile = undefined;
      this.jsonFileContent = undefined;
    }
  },
  i18n: {
    messages: {
      en: {
        import: "Import study",
        updateStudy: "Do you want to update this study ?",
        alertUpdate: "Be careful, this action is irreversible.",
        notSameStudy: "The imported study must be {required}, got {got}.",
        completed: "Study successfully imported."
      },
      fr: {
        import: "Importer l'étude",
        updateStudy: "Voulez-vous mettre à jour cette étude ?",
        alertUpdate: "Attention cette action est irréversible",
        notSameStudy:
          "Le nom de l'étude importée doit être {required} et non {got}.",
        completed: "Étude importée avec succès."
      }
    }
  },
  components: {
    ActionPanel
  }
};
</script>
