<template>
  <v-card outlined>
    <v-card-title class="d-flex justify-content-between">
      <div class="pt-3 pl-2">{{ $t($route.name.split(" ").join("")) }}</div>
      <div :class="{ 'd-flex': !mobile }">
        <print-crf patient="blank" class="mx-3"></print-crf>
        <print-crf patient="annotated" class="mx-3"></print-crf>
      </div>
    </v-card-title>
    <v-card-text>
      <v-divider></v-divider>
      <study-import></study-import>
      <study-export></study-export>
    </v-card-text>
  </v-card>
</template>

<script>
import StudyImport from "../components/admin/StudyImport.vue";
import StudyExport from "../components/admin/StudyExport.vue";
import PrintCrf from "../components/tools/PrintCrf.vue";
export default {
  components: { StudyImport, StudyExport, PrintCrf },
  i18n: {
    messages: {
      en: {
        Adminstudy: "Study administration"
      },
      fr: {
        Adminstudy: "Admnistration de l'étude"
      }
    }
  }
};
</script>
